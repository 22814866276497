<template>
  <div>
    <header>
      <div class="Header_Menu">
        <div
          id="Porta_Btn_OpenMenu"
          class="Porta_Btn_OpenMenu d-flex align-items-center justify-content-center"
        >
          <button
            type="button"
            @click="OpenMenu()"
            class="Btn_OpenMenu"
            id="Btn_OpenMenu"
          >
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button
            type="button"
            @click="CloseMenu()"
            class="Btn_CloseMenu Hide_Btn_CloseMenu"
            id="Btn_CloseMenu"
          >
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Btn_OpenMenu_Mobile">
          <button
            type="button"
            @click="openMenuMobile()"
            id="Btn_OpenMenu_Mobile"
            class="Btn_OpenMenu_Mobile"
          >
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button
            type="button"
            class="Btn_CloseMenu_Mobile Hide_Btn_CloseMenu"
            @click="closeMenuMobile()"
            id="Btn_CloseMenu_Mobile"
          >
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Menu" id="Porta_Menu">
          <div class="container-fluid">
            <div
              class="PortaLogo AlturaMenu d-flex align-items-center justify-content-start"
            >
              <a class="BtnScroll d-none d-sm-block" href="#Home">
                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_Color"
                  alt="LensXpert"
                />
                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_White Hide_Logo_White"
                  alt="LensXpert"
                />
              </a>

              <a class="btn-scroll d-block d-sm-none" href="javascript:void(0)">
                <img
                  src="./svg/LensXpert___LogoMobile.svg"
                  class="Logo_HeaderMenu"
                  alt="LensXpert"
                />
              </a>
            </div>

            <div class="Dropdown" id="Dropdown">
              <div class="PortaLinks" id="PortaLinks">
                <ul>
                  <li>
                    <a class="LinkMenu" href="/">{{
                      $t('public.headMenu.home')
                    }}</a>
                  </li>
                  <li>
                    <a
                      class="LinkMenu BtnScroll LinkGo"
                      id="TomadorDeM"
                      href="#TomadorDeMedidas"
                      @click="closeMenuMobile()"
                      target="_self"
                      >{{ $t('public.headMenu.measurement') }}<br />{{
                        $t('public.headMenu.taker')
                      }}
                      </a>
                  </li>
                  <li>
                    <a
                      class="LinkMenu BtnScroll LinkGo"
                      href="#ConsultorOptico"
                      @click="closeMenuMobile()"
                      target="_self"
                      >{{ $t('public.headMenu.optical') }}<br />{{
                        $t('public.headMenu.consultant')
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="LinkMenu BtnScroll LinkGo"
                      href="#TabelaOnline"
                      @click="closeMenuMobile()"
                      target="_self"
                      >{{ $t('public.headMenu.lens') }}<br />{{
                        $t('public.headMenu.table')
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="LinkMenu LinkGo BtnScrollMais200"
                      href="#Planos"
                      @click="closeMenuMobile()"
                      >{{ $t('public.headMenu.plans') }}</a
                    >
                  </li>
                  <li class="BtnOnlyDesktop LiMenor">
                    <a href="#FaleConosco" class="BtnScroll">
                      <img
                        src="./svg/Menu_FaleConosco.svg"
                        class="IconeEmail"
                        alt=""
                        title="FALE CONOSCO"
                      />
                    </a>
                  </li>
                  <div class="LiMenor">
                    <Locale :isFromPublic="true" />
                  </div>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="$router.push('/admin')"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="UserIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="logout()"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="LogOutIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="!user">
                    <SignIn />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="OverlayMenu" id="OverlayMenu"></div>

    <first-screen></first-screen>
    <splash></splash>
    <planes></planes>
    <apresentacao></apresentacao>
    <tomador-medidas-online></tomador-medidas-online>
    <consultor-optico-online></consultor-optico-online>
    <tabela-precos></tabela-precos>
    <planes></planes>

    <section class="FaleConosco FaleConoscoHome" id="FaleConosco">
      <div class="container ContainerFaleConosco">
        <div class="col-12 col-md-8 offset-md-2 top100">
          <img
            src="./svg/FaleConosco.svg"
            class="IconeFaleConosco"
            alt="LensXpert"
          />

          <h5
            class="AzulClaro1"
            v-html="$t('public.home.sectionDescription1')"
          ></h5>

          <p
            class="MediumTxt Black top10"
            v-html="$t('public.home.sectionDescription2')"
          ></p>
        </div>
        <br /><br />
 <div class="container">
          <div class="col-12 col-md-8 offset-md-2 r_100">
          <a href=" https://wa.me/message/MEWWLDWHKVYGP1" target="_blank"> <p
            class="MediumTxt  top10"
            v-html="$t('public.home.whatsapp') "
          > </p></a>
</div>
<div class="col-12 col-md-8 offset-md-2 r_100">
          <img src="./images/whatsapp-qr.png" style="border:5px solid black; border-radius:2%"/>
          </div>
</div>

      </div>
    </section>
    <footer>
      <div class="container">
        <div class="col-12">
          <div class="row HeightFooter">
            <img
              src="./svg/LensExpert___Logo.svg"
              class="Logo"
              alt="LensXpert"
            />
            <p
              class="SmallTxt White top20 text-center"
              v-html="$t('public.home.sectionDescription3')"
            ></p>
          </div>
        </div>
      </div>
    </footer>
    <a
      id="BackOnTop"
      href="#Home"
      class="BackOnTop d-flex align-items-center justify-content-center"
    >
      <img src="./svg/BackTop.svg" class="BackIcone" alt="LensXpert" />
    </a>
  </div>
</template>

<script scoped>
import {
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import * as Parallax from './js/parallax.js'

import { ScrollScript } from './js/ScrollScript.js'
import { OpenMenu, CloseMenu } from './js/ScrollMenuButton.js'
import {
  openMenuMobile,
  closeMenuMobile,
} from '@/views/public/js/menuMobile.js'

import store from '@/store'
import router from '@/router'
import TomadorMedidasOnline from '@/views/public/sections/TomadorMedidasOnline.vue'
import ConsultorOpticoOnline from '@/views/public/sections/ConsultorOpticoOnline.vue'
import Apresentacao from '@/views/public/sections/Apresentacao.vue'
import TabelaPrecos from '@/views/public/sections/TabelaPrecos.vue'
import Planes from '@/views/public/sections/Plans.vue'
import Splash from '@/views/public/sections/Splash.vue'
import FirstScreen from '@/views/public/sections/FirstScreen.vue'

import SignIn from '@/components/SignIn.vue'
import Contact from '@/components/Contact.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import useLogin from '@/views/auth/useLogin'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
    Contact,
    SignIn,
    TomadorMedidasOnline,
    ConsultorOpticoOnline,
    Apresentacao,
    TabelaPrecos,
    Planes,
    Splash,
    Locale,
    FirstScreen,
  },

  data() {
    return {
      scene1: null,
      parallax: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    ;(this.scene1 = document.getElementById('scene1')),
      (this.parallax = new Parallax(scene1))

    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'scripts')
    document.head.appendChild(externalScript)
  },

  created() {
    window.addEventListener('scroll', ScrollScript)
  },

  methods: {
    ScrollScript,
    OpenMenu,
    CloseMenu,
    openMenuMobile,
    closeMenuMobile,
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
  },

  setup() {},
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

/*@import 'css/cubeportfolio.min.css';*/
/*@import 'css/font-awesome.min.css';*/
@import 'css/jquery.fancybox.css';
@import 'css/MyColors.css';
@import 'css/MyForm.css';
@import 'css/MyNav.css';
@import 'css/MyResponsive.css';
@import 'css/MyStyle.css';
@import 'css/style.css';

.card-title {
  font-weight: bold;
  font-size: 30px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

p strong {
  font-weight: 600;
}

a {
  background: transparent;
  outline: none;
  text-decoration: none;

  -webkit-transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
}

a:active,
a:hover,
a:focus {
  outline: 0;
  color: #1c1c1c;
  text-decoration: none;
  box-shadow: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: 0;
  outline: 0;
  box-shadow: none;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 3px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

h1 span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

footer {
  width: 100%;
  height: 300px;
  background: #0c8cb9 url('../../views/public/images/Footer_HeaderHome.png') top
    center no-repeat;
  background-size: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

footer .HeightFooter {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .Logo {
  width: auto;
  height: 85px;
  margin: 0 0 0 0;
}

footer .Assinatura {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

footer .SmallTxt {
  letter-spacing: 2px;
}
@media (max-width: 1366px) {
  h1 {
    font-size: 15px;
    line-height: 20px;
  }

  h2 {
    font-size: 45px;
    line-height: 50px;
  }

  .Splash .PortaTextoHome {
    padding-left: 40px;
  }

  .Splash p {
    font-size: 14px;
    line-height: 23px;
    width: 90%;
  }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 20%;
  float: left;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
