export function openMenuMobile(){
	// MENU MOBILE
    document.getElementById('PortaLinks').classList.add('Show_PortaLinks');
    document.getElementById('Dropdown').classList.add('AtivaMouse_Dropdown');
    document.getElementById('Btn_OpenMenu_Mobile').classList.add('Hide_Btn_OpenMenu');
    document.getElementById('Btn_CloseMenu_Mobile').classList.remove('Hide_Btn_CloseMenu');
    //document.getElementById('.PortaBtMais').classList.add('Desce_PortaBtMais');
    //document.getElementById('.CoverSocial').classList.add('Desce_CoverSocial');
    document.getElementById('OverlayMenu').classList.add('Show_OverlayMenu');
    //document.querySelector('body').classList.add('Desabilita_Scroll'); --> WHY?
    //document.getElementById('#back-to-top').classList.add('Desce_BackToTop');

}

	// CLOSE MENU MOBILE
export function closeMenuMobile(){
    document.getElementById('PortaLinks').classList.remove('Show_PortaLinks');
    document.getElementById('Btn_OpenMenu_Mobile').classList.remove('Hide_Btn_OpenMenu');
    document.getElementById('Btn_CloseMenu_Mobile').classList.add('Hide_Btn_CloseMenu');
    document.getElementById('Dropdown').classList.remove('AtivaMouse_Dropdown');
    //document.getElementById('PortaBtMais').classList.remove('Desce_PortaBtMais');
    //document.getElementById('CoverSocial').classList.remove('Desce_CoverSocial');
    document.getElementById('OverlayMenu').classList.remove('Show_OverlayMenu');
    //document.getElementById('body').classList.remove('Desabilita_Scroll'); --> WHY?
    //document.getElementById('#back-to-top').classList.remove('Desce_BackToTop');
}


