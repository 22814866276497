<template>
  <section>
    <div id="Home" class="OverlayMenu"></div>
    <div class="Cover_Social d-none d-sm-flex">
      <div class="DivHastag">
        <p class="Verde1 MediumTxt">#Lensxpert</p>
      </div>
      <div class="DivBtnSocial">
        <a href="https://www.instagram.com/" target="_blank">
          <img
            src="../svg/SocialInstagram.svg"
            class="img-fluid"
            alt="Lensxpert"
          />
        </a>
      </div>
      <div class="DivBtnSocial">
        <a href="https://www.facebook.com" target="_blank">
          <img
            src="../svg/SocialFacebook.svg"
            class="img-fluid"
            alt="Lensxpert"
          />
        </a>
      </div>
    </div>
    <div id="video-lightbox" class="VideoLightBox"></div>
    <div
      class="DivVideo"
      @mouseover="MouseOverAssista()"
      @mouseleave="MouseLeaveAssista()"
    >
      <div class="PortaVideo" id="Open_Franqueado">
        <h2>{{ $t('public.home.sectionFeatures1') }}</h2>
      </div>

      <div class="CliqueAqui">
        <a
          class="cbp-lightbox HideScroll"
          href="https://player.vimeo.com/video/442742557?app_id=122963"
          target="_blank"
          >{{ $t('public.home.sectionFeatures2') }}</a
        >
      </div>
    </div>
    <div class="DivParallax d-none d-sm-block" id="scene1">
      <div class="ParallaxMacbook" data-depth="-0.03"></div>
      <div class="ParallaxIphone" data-depth="0.05"></div>
      <div class="ParallaxImac" data-depth="0.03"></div>
      <div class="ParallaxMouse" data-depth="-0.15"></div>
    </div>
  </section>
</template>

<script>
import {
  MouseOverAssista,
  MouseLeaveAssista,
} from '@/views/public/js/AssistaButton.js'

export default {
  methods: {
    MouseOverAssista,
    MouseLeaveAssista,
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

@import '../css/cubeportfolio.min.css';
@import '../css/font-awesome.min.css';
@import '../css/jquery.fancybox.css';
@import '../css/MyColors.css';
@import '../css/MyForm.css';
@import '../css/MyNav.css';
@import '../css/MyResponsive.css';
@import '../css/MyStyle.css';
@import '../css/style.css';

.card-title {
  font-weight: bold;
  font-size: 30px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

p strong {
  font-weight: 600;
}

a {
  background: transparent;
  outline: none;
  text-decoration: none;

  -webkit-transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
}

a:active,
a:hover,
a:focus {
  outline: 0;
  color: #1c1c1c;
  text-decoration: none;
  box-shadow: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: 0;
  outline: 0;
  box-shadow: none;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 3px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

h1 span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

footer .HeightFooter {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .Logo {
  width: auto;
  height: 85px;
  margin: 0 0 0 0;
}

footer .Assinatura {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

footer .SmallTxt {
  letter-spacing: 2px;
}
@media (max-width: 1366px) {
  h1 {
    font-size: 15px;
    line-height: 20px;
  }

  h2 {
    font-size: 45px;
    line-height: 50px;
  }

  .Splash .PortaTextoHome {
    padding-left: 40px;
  }

  .Splash p {
    font-size: 14px;
    line-height: 23px;
    width: 90%;
  }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 20%;
  float: left;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
